import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {

  constructor(private http: HttpClient ) { }

  adminLogin(login_info) {
    return this.http.post(environment.apiUrl + '/api/2020/authenticate', login_info);
  }

  getAllProjects() {
    const path = environment.apiUrl + '/api/2020/getProjects';
    return this.http.get(path);
  }

  getProjectInfo(id) {
    const path = environment.apiUrl + '/api/2020/getProjectInfo/' + id;
    return this.http.get(path);
  }

  getAllUsers(){
    const path = environment.apiUrl + '/api/2020/getUser';
    return this.http.get(path);
  }

  getAllUsers1(sortBy, sortOrder, skipRecords, recordLimit, filterBy, status) {
    filterBy = filterBy === 'All' ? '' : filterBy;
    const path = environment.apiUrl + '/api/2020/getActiveUsers';
    return this.http.get(path, {
      params: {
        sortBy: sortBy,
        sortOrder: sortOrder,
        skipRecords: skipRecords,
        recordLimit: recordLimit,
        filterBy: filterBy,
        status: status
      }
    });
  }

  getUserInfo(id) {
    const path = environment.apiUrl + '/api/2020/getUserInfo/' + id;
    return this.http.get(path);
  }

  getAllLinkedUsers(id) {
    const path = environment.apiUrl + '/api/2020/getAllLinkedUsers/' + id;
    return this.http.get(path);
  }

  addOrEditProject(project_info) {
    project_info.startDate = moment(project_info.startDate).format('DD-MM-YYYY');
    project_info.endDate = moment(project_info.endDate).format('DD-MM-YYYY');
    if (project_info.id) {
      const path = environment.apiUrl + '/api/2020/updateProject/' + project_info.id;
      return this.http.put(path, project_info);
    } else {
      const path = environment.apiUrl + '/api/2020/createProject';
      return this.http.post(path, project_info);
    }
  }

  addOrEditUser(user_info) {
    user_info.dateOfJoin =  moment(user_info.dateOfJoin).format('DD-MM-YYYY');
    user_info.dateOfBirth =  moment(user_info.dateOfBirth).format('DD-MM-YYYY');
    if (user_info.id) {
      const path = environment.apiUrl + '/api/2020/updateUser/' + user_info.id;
      return this.http.put(path, user_info);
    } else {
      const path = environment.apiUrl + '/api/2020/createUsers';
      return this.http.post(path, user_info);
    }
  }

  getRoles() {
    const path = environment.apiUrl + '/api/2020/getRoles';
    return this.http.get(path);
  }

  deleteUser(id) {
    const path = environment.apiUrl + '/api/2020/deleteUser/' + id;
    return this.http.put(path, id);
  }

}
